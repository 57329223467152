@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import '~semantic-ui-css/semantic.min.css';
@import '~react-semantic-toasts/styles/react-semantic-alert.css';

// Utilities start
$paddings: 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50;
@each $i in $paddings {
	.lp-#{$i} {
		padding-left: #{$i}px;
	}
	.lm-#{$i} {
		margin-left: #{$i}px;
	}

	.rp-#{$i} {
		padding-right: #{$i}px;
	}
	.rm-#{$i} {
		margin-right: #{$i}px;
	}

	.tp-#{$i} {
		padding-top: #{$i}px;
	}
	.tm-#{$i} {
		margin-top: #{$i}px;
	}

	.bp-#{$i} {
		padding-bottom: #{$i}px;
	}
	.bm-#{$i} {
		margin-bottom: #{$i}px;
	}

	.hp-#{$i} {
		@extend .tp-#{$i};
		@extend .bp-#{$i};
	}

	.sp-#{$i} {
		@extend .lp-#{$i};
		@extend .rp-#{$i};
	}

	.hm-#{$i} {
		@extend .tm-#{$i};
		@extend .bm-#{$i};
	}

	.sm-#{$i} {
		@extend .lm-#{$i};
		@extend .rm-#{$i};
	}

	.border-t-#{$i/5} {
		border-top-width: #{$i/5}px;
		border-top-style: solid;
	}

	.border-b-#{$i/5} {
		border-bottom-width: #{$i/5}px;
		border-bottom-style: solid;
	}

	.border-r-#{$i/5} {
		border-right-width: #{$i/5}px;
		border-right-style: solid;
	}

	.border-l-#{$i/5} {
		border-left-width: #{$i/5}px;
		border-left-style: solid;
	}

	.border-#{$i/5} {
		border-width: #{$i/5}px;
		border-style: solid;
	}

	.border-radius-#{$i} {
		border-radius: #{$i}px;
	}

	.opacity-#{$i} {
		opacity: $i/100;
	}
}

.border-grey {
	border-color: rgba(34, 36, 38, 0.15);
}

.fr {
	float: right;
}

.fl {
	float: left;
}

.clickable {
	cursor: pointer;
}

.di {
	display: inline;
}

.db {
	display: block;
}

.dib {
	display: inline-block;
}

.clear:after {
    content: "";
    clear: both;
    display: table;
}

.text-bold {
	font-weight: bold;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-capitalize {
	text-transform: capitalize;
}

$text-colors: blue, green, orange, pink, purple, red, teal, yellow, black, grey, white;
@each $tc in $text-colors{
	.text-#{$tc}{
		color: #{$tc};
	}
}

@import "common.scss";