@import "./variables";

.page-wrapper {
    width: 100%;
    display: flex;
    min-height: 100vh;
    flex-wrap: nowrap;
}

.page-sidenav {
    background-color: #002351;
    transition: width 500ms;
    flex-direction: column;
    flex-shrink: 0;
    display: flex;
    width: 80px;

    .list .item {
        .description, .header {
            color: #FFFFFF;
        }
    }

    @keyframes fadein {
        from { opacity: 0; display: none; }
        to   { opacity: 1; display: inherit;}
    }

    .sidenav-item {
        color: #FFFFFF;
        border-right: 4px solid #002351;

        @mixin mini () {
            padding-left: 1rem;
            padding-right: 1rem;

            & > span {
                font-size: 1.2rem;
                margin-left: 0.7rem;
                animation: fadein 2s;
            }
        }

        &.active {
            border-right: 4px solid #0a69e6;
            background-color: rgba(7, 95, 210, 0.1);
        }

        @media only screen and (min-width : $computerBreakpoint) {
            @include mini();
        }

        .mini & {
            @include mini();
        }
    }

    @mixin mini () {
        text-align: center;

        i.icon {
            font-size: 1.5rem;
        }
    }

    @media only screen and (max-width : $largestTabletScreen) {
        @include mini();
    }
    
    &.mini {
        @include mini();

        .hide-on-mini {
            display: none !important;
        }
    }

    .sidenav-collapse {
        cursor: pointer;
    }

    &:not(.mini) {
        @media only screen and (min-width : $computerBreakpoint) {
            width: 180px;
            text-align: left;
        }

        @media only screen and (min-width : $largeMonitorBreakpoint) {
            width: 220px;
        }
    }
}

.page-container {
    background-color: #F1F1F1;
    flex-grow: 1;
    min-width: 0;

    & > .ui.grid {
        margin-left: 0;
        margin-right: 0;
    }
}

.page-topbar {
    background-color: #FFFFFF;
}

// ag grid overwrites
.ag-grid-editor-segment {
    width: 300px;
    padding: 15px;
}

// overlay 
.overlay-container {
    top: 0;
    right: 0;
    width: 50%;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    padding: 15px;
    justify-content: flex-end;
    background-color: #FFFFFF;
    box-shadow: -5px 0 5px #c9c9c9;

    .activity-history-content {
        overflow-y: scroll;
        max-height: calc(100vh - 120px);
    }
}

.ui.button.profile-color {
    color: #FFFFFF;
    padding-left: 14px;
    padding-right: 14px;
}

// overwrites semantic ui
.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled,
.ui.form .field.disabled > label, .ui.form .fields.disabled > label,
.ui.disabled.dropdown, .ui.dropdown .menu > .disabled.item {
    opacity: 0.8;
}

// overwrites ag grid
.ag-theme-balham .ag-cell {
    padding-left: 5px;
    padding-right: 5px;
}

.ag-theme-balham .ag-header-cell, .ag-theme-balham .ag-header-group-cell {
    padding-left: 6px;
    padding-right: 6px;
}